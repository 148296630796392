import React from "react";
import Layout from '../../components/layout';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ItemWithFinish from "../../components/itemWithFinish";
import { graphql } from 'gatsby';
const aboutPage = ({ data }) => (
	<Layout>
		<Row>
			<Col>
				<h1>Epoxy Paints</h1></Col>
		</Row>
		<ItemWithFinish
			heading="Epoxy Admilitary Grey"
			data="2K Epoxy acts as a top coat paint. Provides superior corrosive resistance & very good binding properties & used over primer."
			package="20 ltr,4ltr,1ltr"
			color="da grey(is632)"
			finish="smooth & s/g"
			dryingTime="surface dry-10-15 minutes, hard dry:12-16hrs"
			image={data.syntheticEnamelsMain.childImageSharp.fluid}
		></ItemWithFinish>

		{/* here */}

		<ItemWithFinish
			heading="Epoxy light Grey"
			data="2K Epoxy acts as a top coat paint. Provides superior corrosive resistance & very good binding properties & used over primer."
			package="20 ltr,4ltr,1ltr"
			color="light Grey"
			finish="smooth & matt"
			dryingTime="surface dry-10-15 minutes, hard dry:12-16hrs"
			image={data.syntheticEnamelsMain.childImageSharp.fluid}
		></ItemWithFinish>

		<ItemWithFinish
			heading="Epoxy silver Grey"
			data="2K Epoxy acts as a top coat paint. Provides superior corrosive resistance & very good binding properties & used over primer."
			package="20 ltr,4ltr,1ltr"
			color="Silver Grey"
			finish="smooth & matt"
			dryingTime="surface dry-10-15 minutes, hard dry:12-16hrs"
			image={data.syntheticEnamelsMain.childImageSharp.fluid}
		></ItemWithFinish>


		<ItemWithFinish
			heading="Epoxy Aircraft Grey"
			data="2K Epoxy acts as a top coat paint. Provides superior corrosive resistance & very good binding properties & used over primer."
			package="20 ltr,4ltr,1ltr"
			color="Aircraft Grey"
			finish="smooth & matt"
			dryingTime="surface dry-10-15 minutes, hard dry:12-16hrs"
			image={data.syntheticEnamelsMain.childImageSharp.fluid}
		></ItemWithFinish>

		<ItemWithFinish
			heading="Epoxy Dove Grey"
			data="2K Epoxy acts as a top coat paint. Provides superior corrosive resistance & very good binding properties & used over primer."
			package="20 ltr,4ltr,1ltr"
			color="Dove grey"
			finish="smooth & matt"
			dryingTime="surface dry-10-15 minutes, hard dry:12-16hrs"
			image={data.syntheticEnamelsMain.childImageSharp.fluid}
		></ItemWithFinish>


		<ItemWithFinish
			heading="Epoxy French Grey"
			data="2K Epoxy acts as a top coat paint. Provides superior corrosive resistance & very good binding properties & used over primer."
			package="20 ltr,4ltr,1ltr"
			color="French Grey"
			finish="smooth & matt"
			dryingTime="surface dry-10-15 minutes, hard dry:12-16hrs"
			image={data.syntheticEnamelsMain.childImageSharp.fluid}
		></ItemWithFinish>


		<ItemWithFinish
			heading="Epoxy Traffic Red"
			data="2K Epoxy acts as a top coat paint. Provides superior corrosive resistance & very good binding properties & used over primer."
			package="20 ltr,4ltr,1ltr"
			color="Traffic Red"
			finish="smooth & matt"
			dryingTime="surface dry-10-15 minutes, hard dry:12-16hrs"
			image={data.syntheticEnamelsMain.childImageSharp.fluid}
		></ItemWithFinish>


		<ItemWithFinish
			heading="Epoxy Post Office Red"
			data="2K Epoxy acts as a top coat paint. Provides superior corrosive resistance & very good binding properties & used over primer."
			package="20 ltr,4ltr,1ltr"
			color="Post office red"
			finish="smooth & matt"
			dryingTime="surface dry-10-15 minutes, hard dry:12-16hrs"
			image={data.syntheticEnamelsMain.childImageSharp.fluid}
		></ItemWithFinish>


		<ItemWithFinish
			heading="Epoxy International Orange"
			data="2K Epoxy acts as a top coat paint. Provides superior corrosive resistance & very good binding properties & used over primer."
			package="20 ltr,4ltr,1ltr"
			color="International orange"
			finish="smooth & matt"
			dryingTime="surface dry-10-15 minutes, hard dry:12-16hrs"
			image={data.syntheticEnamelsMain.childImageSharp.fluid}
		></ItemWithFinish>




		<ItemWithFinish
			heading="Epoxy Sky Blue"
			data="2K Epoxy acts as a top coat paint. Provides superior corrosive resistance & very good binding properties & used over primer."
			package="20 ltr,4ltr,1ltr"
			color="Oxford blue"
			finish="smooth & matt"
			dryingTime="surface dry-10-15 minutes, hard dry:12-16hrs"
			image={data.syntheticEnamelsMain.childImageSharp.fluid}
		></ItemWithFinish>

			{/* Commented because of noaccess to actual data */}

		{/* <ItemWithFinish
			heading="Epoxy Azure Blue"
			data="2K Epoxy acts as a top coat paint. Provides superior corrosive resistance & very good binding properties & used over primer."
			package="20 ltr,4ltr,1ltr"
			color="da grey(is632)"
			finish="smooth & s/g"
			dryingTime="surface dry-10-15 minutes, hard dry:12-16hrs"
			image={data.syntheticEnamelsMain.childImageSharp.fluid}
		></ItemWithFinish>

		
		<ItemWithFinish
			heading="Epoxy French Blue"
			data="2K Epoxy acts as a top coat paint. Provides superior corrosive resistance & very good binding properties & used over primer."
			package="20 ltr,4ltr,1ltr"
			color="da grey(is632)"
			finish="smooth & s/g"
			dryingTime="surface dry-10-15 minutes, hard dry:12-16hrs"
			image={data.syntheticEnamelsMain.childImageSharp.fluid}
		></ItemWithFinish>
		<ItemWithFinish
			heading="Epoxy Traffic Blue"
			data="2K Epoxy acts as a top coat paint. Provides superior corrosive resistance & very good binding properties & used over primer."
			package="20 ltr,4ltr,1ltr"
			color="da grey(is632)"
			finish="smooth & s/g"
			dryingTime="surface dry-10-15 minutes, hard dry:12-16hrs"
			image={data.syntheticEnamelsMain.childImageSharp.fluid}
		></ItemWithFinish> */}


		{/* <ItemWithFinish
			heading="Epoxy Aircraft Blue"
			data="2K Epoxy acts as a top coat paint. Provides superior corrosive resistance & very good binding properties & used over primer."
			package="20 ltr,4ltr,1ltr"
			color="da grey(is632)"
			finish="smooth & s/g"
			dryingTime="surface dry-10-15 minutes, hard dry:12-16hrs"
			image={data.syntheticEnamelsMain.childImageSharp.fluid}
		></ItemWithFinish> */}

				{/* <ItemWithFinish
			heading="Epoxy Oxford Blue"
			data="2K Epoxy acts as a top coat paint. Provides superior corrosive resistance & very good binding properties & used over primer."
			package="20 ltr,4ltr,1ltr"
			color="da grey(is632)"
			finish="smooth & s/g"
			dryingTime="surface dry-10-15 minutes, hard dry:12-16hrs"
			image={data.syntheticEnamelsMain.childImageSharp.fluid}
		></ItemWithFinish> */}

		{/* <ItemWithFinish
			heading="Epoxy Smoke Grey"
			data="2K Epoxy acts as a top coat paint. Provides superior corrosive resistance & very good binding properties & used over primer."
			package="20 ltr,4ltr,1ltr"
			color="da grey(is632)"
			finish="smooth & s/g"
			dryingTime="surface dry-10-15 minutes, hard dry:12-16hrs"
			image={data.syntheticEnamelsMain.childImageSharp.fluid}
		></ItemWithFinish> */}


	</Layout>
)

export default aboutPage;
export const query = graphql`
query {
    syntheticEnamelsMain: file(relativePath: { eq: "syntheticEnamelsMain.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }}`